@import "./typography";
@import "./buttons";
@import "./headings";
@import "./inputs";
@import "./text";
@import "./icons";
@import "./dialog";

html,
body {
  height: 100%;
  min-height: 100%;
}
