/* You can add global styles to this file, and also import other style files */

@import "theme/material-design-custom";
@import "theme/layout";

.blue-text {
  /* eslint-disable-next-line declaration-block-no-shorthand-property-overrides */

  font: 300 26px/32px Roboto, monospace;
  font-size: 26px;
  margin-left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #004a7c;
}

.blue-text2 {
  margin-left: 20px;
  font: 300 32px/32px Roboto, monospace;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #004a7c;
}

.blueBar {
  background-color: #004a7c;
  width: 100%;
  height: 50px;
}

.spacer {
  padding-top: 60px;
}

.spacer2 {
  padding-top: 25px;
}

.container {
  width: 400px;
  padding-left: 10px;
}

.line {
  height: 30px;
  margin: 15px;
  animation: pulse 1s infinite ease-in-out;
}
$padding-margin-maxlimit: 60;

@for $i from 1 through $padding-margin-maxlimit {
  .p-#{$i} {
    padding: #{$i}px;
  }

  .m-#{$i} {
    margin: #{$i}px;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }

  .pt-#{$i} {
    padding-top: #{$i}px;
  }

  .pl-#{$i} {
    padding-left: #{$i}px;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }
}
