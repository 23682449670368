@use "@angular/material" as mat;
@use "./colors" as c;

/* Plus imports for other components in your app. */

/* Include the common styles for Angular Material. We include this here so that you only
   have to load a single css file for Angular Material in your app.
   Be sure that you only ever include this mixin once! */
@include mat.core();

/* Define the palettes for your theme using the Material Design palettes available in palette.scss
   (imported above). For each palette, you can optionally specify a default, lighter, and darker
   hue. Available color palettes: https://www.google.com/design/spec/style/color.html */
$db-app-primary: mat.define-palette(c.$rs-blue-pal, 500, 400, 600);
$db-app-accent: mat.define-palette(c.$rs-blue-pal, 300, 300, 400);

/* The warn palette is optional (defaults to red). */
$db-app-warn: mat.define-palette(c.$error-palette, 400, 300, 500);

/* Create the theme object (a Sass map containing all of the palettes). */
$db-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $db-app-primary,
      accent: $db-app-accent,
      warn: $db-app-warn,
    ),
  )
);

/* Include theme styles for core and each component used in your app.
   Alternatively, you can import and @include the theme mixins for each component
   that you are using. */
@include mat.all-component-themes($db-app-theme);

/* Define a custom typography config that overrides the font-family as well as the
 build in levels. Uncomment here to override material defaults */
$custom-typography: mat.define-typography-config(
  $font-family: "Roboto, monospace",
  //$headline-1: mat.define-typography-level(112px, 112px, 300),
  //$headline-2: mat.define-typography-level(56px, 56px, 400),
  //$headline-3: mat.define-typography-level(45px, 48px, 400),
  //$headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(32px, 48px, 300),
  $body-1: mat.define-typography-level(14.5px, 24px, 400),
  $body-2: mat.define-typography-level(14.5px, 24px, 400),
  $subtitle-2: mat.define-typography-level(13.2px, 24px, 400),
  $headline-6: mat.define-typography-level(26px, 32px, 300), /* <h2> tag */
  //$subtitle-1: mat.define-typography-level(16px, 28px, 400),
  //$caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);

/* Fixes tabs scrolling to top */

/* @see https://github.com/angular/material2/issues/9592#issuecomment-396796971 */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-group-container {
  min-height: stretch;
}

.mat-mdc-snack-bar-container {
  border-radius: 2px !important;
  margin: 0px !important;
  max-width: 568px !important;
  min-width: 410px !important;
  padding: 14px 24px !important;
}

.mat-mdc-snack-bar-label {
  color: white;
}

.mat-mdc-button {
  margin-right: 12px !important;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label{
  max-width: 100%;
}
