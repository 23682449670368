/* it don't matter if you're black or white... */
$black: #000;
$white: #fff;

$brand-tint: #1890e2;
$brand: #004a7c;
$brand-shade: #003254;
$brand-palette: (
  300: $brand-tint,
  400: $brand,
  500: $brand-shade,
  contrast: (
    300: $black,
    400: $white,
    500: $white,
  ),
);

$brand-light-tint: #a6e0f7;
$brand-light: #00adef;
$brand-light-shade: #006e98;
$brand-light-palette: (
  300: $brand-light-tint,
  400: $brand-light,
  500: $brand-light-shade,
  contrast: (
    300: $black,
    400: $white,
    500: $white,
  ),
);

$success-tint: #c8e6c9;
$success: #4caf50;
$success-shade: #2e7d32;
$success-palette: (
  300: $success-tint,
  400: $success,
  500: $success-shade,
  contrast: (
    300: $black,
    400: $white,
    500: $white,
  ),
);

$warning-tint: #ffc107;
$warning: #ffe082;
$warning-shade: #ff8f00;
$warning-palette: (
  300: $warning-tint,
  400: $warning,
  500: $warning-shade,
  contrast: (
    300: $black,
    400: $white,
    500: $white,
  ),
);

$error-tint: #ffcdd2;
$error: #f44336;
$error-shade: #c62828;
$error-palette: (
  300: $error-tint,
  400: $error,
  500: $error-shade,
  contrast: (
    300: $black,
    400: $white,
    500: $white,
  ),
);

$gray-tint: #c7c8ca;
$grey-tint: $gray-tint;
$gray: #777;
$grey: $gray;
$gray-shade: #373737;
$grey-shade: $gray-shade;
$gray-palette: (
  300: $gray-tint,
  400: $gray,
  500: $gray-shade,
  contrast: (
    300: $black,
    400: $white,
    500: $white,
  ),
);
$grey-palette: $gray-palette;

$gray-light-tint: #f7f7f7;
$grey-light-tint: $gray-light-tint;
$gray-light: #e0e0e0;
$grey-light: $gray-light;
$gray-light-shade: #c4c4c4;
$grey-light-shade: $gray-light-shade;
$gray-light-palette: (
  300: $gray-light-tint,
  400: $gray-light,
  500: $gray-light-shade,
  contrast: (
    300: $black,
    400: $black,
    500: $black,
  ),
);
$grey-light-palette: $gray-light-palette;

/* DEPRECATED: need to update theme to use $brand-palette instead of this */
$rs-blue-pal: (
  300: $brand-light,
  400: $brand-tint,
  500: $brand,
  600: $brand-shade,
  contrast: (
    300: $white,
    400: $black,
    500: $white,
    600: $white,
  ),
);

.success {
  color: $success;
}

.error {
  color: $error;
}

.brand {
  color: $brand;
}

.brand-shade {
  color: $brand-shade;
}

.gray {
  color: $gray;
}

.gray-shade {
  color: $gray-shade;
}

.success-inv {
  background-color: $success;
  color: white;
}

.error-inv {
  background-color: $error;
  color: white;
}

.gray-shade-inv {
  background-color: $gray-shade;
  color: white;
}

.gray-light-tint-back {
  background-color: $gray-light-tint;
}
