@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

/* recommended material icons sizes */
.material-icons.mat-18 {
  @include md-icon-size(18px);
}

.material-icons.mat-24 {
  @include md-icon-size(24px);
}

.material-icons.mat-36 {
  @include md-icon-size(36px);
}

.material-icons.mat-48 {
  @include md-icon-size(48px);
}

/* fix for icon alignment with text */
.material-icons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
