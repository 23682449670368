@use "./colors" as c;

button:focus {
  background-color: transparent;
}

.clickable {
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: c.$brand;
  color: c.$white;
}

a {
  color: c.$brand-light;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    color: c.$brand-light-shade;
  }
}
