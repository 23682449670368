@import "./colors";

$heading-base-size: 14px;
$heading-weight-normal: 300;
$heading-weight-heavy: 400;

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $brand;
  font-weight: $heading-weight-normal;
}

h1 {
  font-size: $heading-base-size + 16px;
}

h2 {
  font-size: $heading-base-size + 12px;
}

h3 {
  font-size: $heading-base-size + 6px;
  font-weight: $heading-weight-heavy;
}

h4 {
  font-size: $heading-base-size + 4px;
}

h5 {
  font-size: $heading-base-size + 2px;
}

h6 {
  font-size: $heading-base-size;
}
