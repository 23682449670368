@use "./colors" as c;

.mat-mdc-dialog-container {
  .mat-mdc-dialog-title.mdc-dialog__title {
    color: c.$brand-shade;
  }
}

.mat-mdc-dialog-container {
  .mat-mdc-dialog-content.mdc-dialog__content {
    color: black;
    font-family: 'Times New Roman', Times, serif;
  }
}
